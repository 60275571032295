<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Beheer CV variaties" subtitle="Beheer CV variaties die bijv. per prospect gedeeld kunnen worden" :route="'/cv/resumes/'+user_id+'/new'" />

    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="c in content" :key="c.id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{c.name}}
                          </div>
                          <div class="text-sm text-gray-500">
                            Laatst bijgewerkt: {{moment(c.updatedAt).format('LLL')}}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <router-link :to="'/cv/resumes/'+user_id+'/'+c.id+'/edit'" class="text-orange-600 hover:text-orange-900">edit</router-link>
                    </td>
                  </tr>

                </tbody>
              </table>
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-link :to="'/cv/resumes/'+user_id+'/new'" class="inline-flex justify-center w-full mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">Add resume</router-link>
  </main>
</template>

<script>
import CvService from '../../../../services/admin/cv/service';
import moment from 'moment';
export default {
  name: 'Resumes',
  data() {
    return {
      content: '',
      user_id: null
    };
  },
  created: function () {
    this.moment = moment;
  },
  methods: {
    fetchData() {
      CvService.getResumes(this.$route.params.user_id).then(
        response => {
          this.content = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          if (error.response.status === 401)
            this.$router.push(`/auth`);
        }
      );
    }
  },
  mounted() {
    this.fetchData();
    this.user_id = this.$route.params.user_id;
  }
};
</script>
